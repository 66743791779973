.l5r-dice {
	width: 28px;
	height: 27px;
	background: white;
	background-size: contain;
	position: relative;
	top: -4px;
	left: -4px;
	&.strife {
		background-image: url('/images/dices/l5r/strife.png');
	}
	&.opportunity {
		background-image: url('/images/dices/l5r/opportunity.png');
	}
	&.success {
		background-image: url('/images/dices/l5r/success.png');
	}
	&.explosive-success {
		background-image: url('/images/dices/l5r/explosive-success.png');
	}
	&.two_Values {
		background-position: right bottom, left top;
		background-size: 14px, 14px;
		background-repeat: no-repeat;
	}
	&.two_Values.strife.opportunity {
		background-image: url('/images/dices/l5r/strife.png'),
			url('/images/dices/l5r/opportunity.png');
	}
	&.two_Values.strife.success {
		background-image: url('/images/dices/l5r/strife.png'),
			url('/images/dices/l5r/success.png');
	}
	&.two_Values.strife.explosive-success {
		background-image: url('/images/dices/l5r/strife.png'),
			url('/images/dices/l5r/explosive-success.png');
	}
}
