.camera {
	.video {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		video,
		.fake-video {
			position: absolute;
			max-height: 95%;
			max-width: 100%;
		}
		.fake-video {
			background: black;
		}
		h1 {
			position: absolute;
			bottom: 0;
			margin: 0;
			color: white;
			text-shadow: 1px 1px black;
			font-size: 104px;
		}
		.avatar {
			position: absolute;
			bottom: 0;
		}
	}

	.userName {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		display: none;
	}
	.diceContainer {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 50px;
		width: 100%;
		display: flex;
	}
	.cameraButtons {
		position: absolute;
		top: 0;
		left: 0;
		height: 100px;
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.responsiveCamera {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	.video {
		height: 100%;
		position: relative;
		video,
		.fake-video {
			width: 100%;
			height: 100%;
		}
		.fake-video {
			background-color: black;
		}
		h1 {
			position: absolute;
			bottom: 0;
			color: white;
			text-shadow: 1px 1px black;
			font-size: 16px;
			margin: 0;
			width: 100%;
			text-align: center;
		}
		.avatar {
			position: absolute;
			bottom: 0;
		}
	}
	.diceContainer {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 63px;
		width: 100%;
		display: flex;
		margin: 0;
	}
	.cameraButtons {
		position: absolute;
		top: 0;
		left: 0;
		height: 100px;
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.dice {
	background: url('/images/d10.png') no-repeat center transparent;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 50px;
	width: 100px;
	background-size: contain;
	color: white;
}
.newDice {
	position: relative;
	svg {
		color: black;
	}
	span {
		color: white;
		background: black;
		position: absolute;
		font-size: 16px;
		left: 10px;
		top: 10px;
		width: 19px;
		text-align: center;
		border-radius: 50px;
	}
}
